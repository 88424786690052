<template>
  <div class="content-analysis">
    <div class="header-box">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item v-for="item in menuConfig" :key="item.index" :index="item.index">{{ item.name }}</el-menu-item>
      </el-menu>
    </div>

    <component :is="getComponents"></component>
  </div>
</template>

<script>
import Posts from "@/components/dataCenter/Posts.vue";
import LiveBroadcast from "@/components/dataCenter/LiveBroadcast.vue";
export default {
  data() {
    return {
      activeIndex: "1",
      menuConfig: [
        {
          index: "1",
          name: "投稿作品",
          component: "Posts",
        },
        {
          index: "2",
          name: "直播场次",
          component: "LiveBroadcast",
        },
      ],
    };
  },

  computed: {
    getComponents() {
      const component = this.menuConfig.find((item) => item.index == this.activeIndex);
      return component.component;
    },
  },

  components: {
    Posts,
    LiveBroadcast,
  },

  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped lang="scss">
.content-analysis {
  padding: 24px;
  background-color: #fff;

  .header-box {
    /* element ui style */
    ::v-deep .el-menu-item {
      color: rgba(37, 38, 50, 0.6);
      font-family: PingFang SC, PingFang SC;
      font-size: 18px;
      padding: 0 4px;
      margin-right: 24px;
      height: 50px;
      line-height: 50px;
      // line-height: 21px;
      font-weight: 400;
    }

    ::v-deep .el-menu-item.is-active {
      color: #252632;
    }
  }
}
</style>