<template>
  <div class="live-broadcast">
    <div class="item">
      <div class="item-header">
        <div class="item-header_title">
          <span>场次列表</span>
          <el-popover popper-class="black-popover" trigger="hover" content="所选周期内的直播场次数据，数据每天更新" width="180">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>

          <div class="refresh" @click="refresh">
            <i class="el-icon-refresh-right"></i>
            <span>刷新</span>
          </div>
        </div>

        <div class="item-header_end">
          <div class="filter-box">
            <div class="account-filter filter-item">
              <span>账号筛选</span>
              <el-select
                v-model="filterParams.filterAccounts"
                multiple
                collapse-tags
                :popper-append-to-body="false"
                style="margin-left: 20px"
                placeholder="请选择"
                size="mini"
              >
                <el-option v-for="item in accountList" :key="item.id" :label="item.nickname" :value="item.id">
                  <div class="account-item">
                    <img v-if="!item.avatar" src="@/assets/img/image/blank_headPic.png" alt="" />
                    <img v-else :src="item.avatar" alt="" />
                    <span>{{ item.nickname }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>

            <div class="data-filter filter-item">
              <span>发布时间</span>
              <el-date-picker
                v-model="filterParams.filterTime"
                :clearable="false"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                popper-class="date-no-light"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="item-header_operation">
            <el-button icon="el-icon-download" @click="exportData">导出数据</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="container-box">
      <el-scrollbar style="height: 100%" class="custom-scrollbar" ref="scrollbar">
        <el-table
          :data="itemList"
          :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4' }"
          :cell-style="{ fontSize: '14px', color: ' #252632' }"
          size="mini"
        >
          <!-- <el-table-column type="index" width="50" fixed="left"> </el-table-column> -->
          <template v-for="item in tableConfig">
            <el-table-column
              :key="item.id"
              v-if="item.show"
              :width="item.width"
              :fixed="item.fixed ? item.fixed : false"
              :label="item.label"
              :align="item.align"
            >
              <template slot-scope="scope">
                <!-- 默认 -->
                <div v-if="!item.customLayout" class="default-column">{{ scope.row[item.id] }}{{ item.unit ? item.unit : "" }}</div>

                <!-- 自定义 -->
                <template v-if="item.customLayout">
                  <template v-if="item.id == 'account'">
                    <div class="account-column">
                      <img :src="scope.row.avatar" alt="" class="account-img" />
                      <span>{{ scope.row.nickname }}</span>
                    </div>
                  </template>

                  <template v-if="item.id == 'title-time'">
                    <div class="title-time-column">
                      <div class="title-time-column">
                        <!-- <img :src="scope.row.avatar" alt="" class="title-time-img" /> -->
                        <div class="title-time-info">
                          <!-- <el-popover popper-class="black-popover" trigger="hover" :content="scope.row.nickname" width="180">
                            <span class="popover-span ellipses" slot="reference" ref="textElement">{{
                              scope.row.nickname + "正在直播"
                            }}</span>
                          </el-popover> -->

                          <div class="info-time">
                            <span>{{ scope.row.live_start }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="item.id == 'operation'">
                    <div class="operation"></div>
                  </template>
                </template>
              </template>
            </el-table-column>
          </template>

          <div class="live-no-data" v-if="itemList.length === 0" slot="empty">
            <img src="@/assets/img/datacenter/live-no-data.svg" alt="" />
            <span>近期没有直播场次，快去直播吧</span>
          </div>
        </el-table>
      </el-scrollbar>
    </div>

    <div class="medio-load" v-if="itemList.length !== 0">
      <!-- <el-pagination
        layout="prev, pager, next"
        :current-page="params.page"
        :total="itemTotal"
        :page-size="params.limit"
        @current-change="handleCurrentChange"
      >
      </el-pagination> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="params.page"
        :page-sizes="[10, 1, 20, 40, 100]"
        :page-size="params.limit"
        layout="prev, pager,next, sizes"
        :total="itemTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import { liveData } from "../../utils/mock";
export default {
  name: "LiveBroadcast",
  data() {
    return {
      tableConfig: [
        {
          id: "account",
          label: "账号",
          show: true,
          customLayout: true,
          fixed: "left",
          width: 250,
          align: "left",
        },
        {
          id: "title-time",
          label: "开播时间",
          show: true,
          customLayout: true,
          width: 350,
          fixed: "left",
          align: "left",
        },
        {
          id: "live_duration",
          label: "直播时长",
          show: true,
          width: 120,
          customLayout: false,
          align: "center",
        },
        {
          id: "watch_num",
          label: "观看人次",
          show: true,
          width: 120,
          customLayout: false,
          align: "center",
        },
        {
          id: "watch_people",
          label: "观看人数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "watch_max_people",
          label: "在线人数峰值",
          show: true,
          customLayout: false,
          width: 150,
          align: "center",
        },
        {
          id: "avg_watch_duration",
          label: "平均观看时长",
          show: true,
          customLayout: false,
          width: 150,
          align: "center",
          unit: "分钟",
        },
        {
          id: "fans_avg_watch_duration",
          label: "粉丝平均观看时长",
          show: true,
          customLayout: false,
          width: 150,
          align: "center",
          unit: "分钟",
        },
        {
          id: "comment_people",
          label: "评论人数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "like_num",
          label: "点赞次数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "share_num",
          label: "转发次数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "follow_people",
          label: "涨粉人数",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "follow_rate",
          label: "关注率",
          show: true,
          customLayout: false,
          width: 120,
          align: "center",
        },
        {
          id: "operation",
          label: "操作",
          show: false,
          customLayout: true,
          fixed: "right",
          // width: 120,
          align: "center",
        },
      ],
      itemList: [],
      itemTotal: 0,
      params: {
        id: "",
        page: 1,
        date: "",
        limit: 10,
      },
      filterParams: {
        filterTime: [],
        filterAccounts: [],
      },
      accountList: [],
      // 日期不能选择今天以及今天以后的
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          console.log(time.getTime() < date.getTime());
          return time.getTime() > date.getTime();
          // return time.getTime() > Date.now()
        },
      },
    };
  },
  watch: {
    "filterParams.filterAccounts": {
      handler(newval) {
        this.getLiveList();
      },
      deep: true,
      immediate: false,
    },
    "filterParams.filterTime": {
      handler(newVal) {
        this.getLiveList();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    initDefaultTime() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setDate(yesterday.getDate() - 7);
      this.filterParams.filterTime = [this.formatDate(oneMonthAgo), this.formatDate(yesterday)];
    },

    getLiveList() {
      if (this.filterParams.filterAccounts.length === 0) {
        const ids = this.accountList.map((item) => item.id);
        this.params.id = ids.toString();
      } else {
        this.params.id = this.filterParams.filterAccounts.toString();
      }

      this.params.date = this.filterParams.filterTime.toString();

      if (this.params.id == "") return;

      this.$httpStudent.axiosGetBy(this.$api.dataCenterLiveAnalysis, this.params, (res) => {
        if (res.code == 200) {
          this.itemList = res.data.list;
          this.itemTotal = res.data.total;
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    getAccountList() {
      let params = {
        page: 1,
        pageSize: 10,
        paging: 0,
      };
      this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
        if (res.code == 200) {
          this.accountList = res.data.data;
          this.getLiveList();
        }
      });
    },

    handleSizeChange(val) {
      this.params.limit = val;
      this.params.page = 1;

      this.getLiveList();
    },

    handleCurrentChange(val) {
      this.params.page = val;
      this.getLiveList();
    },
    // 刷新
    refresh() {
      this.params.page = 1;

      this.getLiveList();
    },
    // 导出
    exportData() {
      // console.log("exportData");
      let params = {
        id: this.filterParams.filterAccounts.toString(),
        date: this.filterParams.filterTime.toString(),
        token: localStorage.getItem("studentToken"),
      };

      if (this.filterParams.filterAccounts.length === 0) {
        const ids = this.accountList.map((item) => item.id);
        params.id = ids.toString();
      } else {
        params.id = this.filterParams.filterAccounts.toString();
      }

      if (params.id == "") return;

      this.$httpStudent.axiosSetTypeGet(this.$api.dataCenterLiveAnalysisExport, params, "arraybuffer", (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "直播场次数据.xlsx"; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
  },
  mounted() {
    this.getAccountList();
    this.initDefaultTime();
  },
};
</script>

<style lang="scss" scoped>
.account-item {
  display: inline-block;
  // align-items: center;
  // gap: 3px;
  margin-left: 20px;
  img {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // background-color: black;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 3px;
    // background-color: black;
  }
}

.live-broadcast {
  padding-block: 24px;

  .ellipses {
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出容器部分隐藏 */
    text-overflow: ellipsis; /* 超出部分用省略号代替 */
  }

  .refresh {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(37, 38, 50, 0.6);
    line-height: 16px;
    cursor: pointer;
  }

  .item {
    margin-bottom: 16px;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #252632;
        display: flex;
        align-items: center;
        gap: 5px;

        .el-icon-question {
          margin-left: 3px;
          width: 14px;
          height: 14px;
          font-size: 14px;
          font-style: normal;
          color: #bbbbbd;
          cursor: pointer;
        }
      }

      &_end {
        display: flex;
        align-items: center;
        gap: 20px;

        ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected .account-item span {
          color: #409eff;
        }

        ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
          left: 20px;
          top: 0;
        }

        .filter-box {
          display: flex;
          align-items: center;
          gap: 12px;

          .filter-item {
            background-color: #f2f2f4;
            border-radius: 4px;
            padding: 2px 12px;

            span {
              color: rgba(37, 38, 50, 0.6);
            }

            ::v-deep .el-input__inner {
              padding-left: 10px;
              background: transparent;
              border: none;
            }

            ::v-deep .el-date-editor .el-range-input {
              background-color: transparent;
            }

            ::v-deep .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 250px;
            }
          }
        }
      }

      &_operation {
        ::v-deep .el-button {
          padding: 6px 12px;
          background-color: #f2f2f4;
          border: none;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #777880;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }

  .live-no-data {
    min-height: calc(100vh - 450px);
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-200px);

    span {
      margin-top: 20px;
      font-family: PingFang SC, PingFang SC;
      font-size: 16px;
      font-weight: 600;
      color: #25263299;
    }
  }

  .container-box {
    min-height: calc(100vh - 350px);
    .account-column {
      display: flex;
      align-items: center;
      gap: 3px;

      .account-img {
        width: 46px;
        height: 46px;
        object-fit: cover;
        border-radius: 50%;
      }

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .title-time-column {
      display: flex;
      align-items: center;
      gap: 5px;

      .title-time-img {
        width: 46px;
        height: 46px;
        object-fit: contain;
        border-radius: 8px;
      }

      .title-time-info {
        display: flex;
        flex-direction: column;

        span.popover-span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #252632;
          text-align: left;
          font-style: normal;
          text-transform: none;
          cursor: pointer;
          display: inline-block;
          width: 170px;
        }

        .info-time {
          span,
          i {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            max-width: 41.5%;
            color: rgba(37, 38, 50, 0.6);
            text-align: left;
            font-style: normal;
          }

          & .icon-label {
            margin-left: 5px;
            color: #252632;
            padding: 1px 3px;
            border: 1px solid #f2f2f4;
          }
        }
      }
    }

    ::v-deep(.el-scrollbar__bar.is-horizontal) {
      height: 0px;
    }

    ::v-deep(.el-scrollbar__bar.is-vertical) {
      width: 0px;
    }

    ::v-deep .el-table__fixed,
    ::v-deep .el-table__fixed-right {
      pointer-events: none;
    } /* 表格固定列-鼠标事件穿透 */
    ::v-deep .el-table__fixed td,
    ::v-deep .el-table__fixed-right td,
    ::v-deep .el-table__fixed th,
    ::v-deep .el-table__fixed-right th {
      pointer-events: auto;
    }

    ::v-deep(.el-table__fixed-right::before),
    ::v-deep(.el-table__fixed::before) {
      background-color: transparent;
    }

    ::v-deep(.el-table td.el-table__cell),
    ::v-deep(.el-table th.el-table__cell.is-leaf) {
      border-bottom: none;
    }

    /* 自定义横向滚动条样式 */
    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar) {
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-track) {
      // background: black;
      height: 10px;
    }

    ::v-deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: none;
      background: #dadbdd;
      border-radius: 5px;

      &:hover {
        display: block;
      }
    }

    ::v-deep(.custom-scrollbar.el-scrollbar:hover .el-table__body-wrapper::-webkit-scrollbar-thumb) {
      display: block;
    }
  }

  .medio-load {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #888888;
      line-height: 19px;
      cursor: pointer;
    }
  }
}
</style>